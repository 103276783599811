import { createTheme } from "@mui/material/styles";
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

const pnjTheme = createTheme({
  
  typography: {
    body1 : {
      color: "#000000",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: 2,
      [breakpoints.down('md')]: {
        fontSize: 15,
      },
      fontSize: 21,
    },
    body2 : {
      color: "#ffffff",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: 2,
      [breakpoints.down('md')]: {
        fontSize: 10,
      },
      fontSize: 14,
    },
    caption : {
      color: "#000000",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: 2,
      [breakpoints.down('md')]: {
        fontSize: 10,
      },
      fontSize: 14,
    },
    h1: {
      color: "#000000",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "54px",
      [breakpoints.down('md')]: {
        fontSize: 40,
      },
      fontSize: 54,
      
    },
    h2: {
      color: "#000000",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      [breakpoints.down('md')]: {
        fontSize: 35,
      },
      fontSize: 49,
    },
    h3: {
      color: "#000000",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      [breakpoints.down('md')]: {
        fontSize: 20,
      },
      fontSize: 28,
    },
    h4: {
      color: "#ffffff",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      textTransform : "uppercase",
      [breakpoints.down('md')]: {
        fontSize: 24,
      },
      fontSize: 31,
    },
  },
  palette: {
    primary: { light: "", main: "#ffffff" },
    secondary: { main: "#ffffff" },
    background: { default: "#02231c" },
  },
});

export default pnjTheme;
