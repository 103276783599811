import { Box } from "@mui/system";
import landscapingBanner from "../../../src/images/landscaping-banner.png";
import landscapingImg2 from "../../../src/images/landscaping-image-2.jpg";

import { Button, Grid, Typography, Hidden, IconButton } from "@mui/material";
import {
  ArrowForwardIos,
  EmailRounded,
  FacebookRounded,
  FacebookTwoTone,
  Person2Rounded,
  PhoneRounded,
  YouTube,
  YoutubeSearchedForTwoTone,
} from "@mui/icons-material";
import forestImg from "../../../src/images/forest.png";
import { createBreakpoints } from '@mui/system';

function Landscaping() {

  const breakpoints = createBreakpoints({});
  return (
    <>
      <Box
        sx={{
          backgroundColor: "green",
          backgroundImage: `url(${landscapingBanner})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          px: 5,
          paddingY: 10,
        }}
      >
        <Typography variant="h1" color={"#FFF"} mt={20} textAlign={"center"}>
          Landscaping
        </Typography>
        <Typography variant="h3" color={"#FFF"} mt={1} textAlign={"center"}>
          Quality that is granted
        </Typography>
        <Typography color={"#FFF"} mt={2} textAlign={"center"}>
          Don’t have the time or expertise to do it yourself? <br />
          We’ll take care of your property so you can relax and enjoy it.
        </Typography>
      </Box>
      <Grid sx={{ backgroundColor: "white" }} container spacing={4} p={5}>
        <Grid item sm={6}>
          <Typography variant="h2">YOU DREAM, WE BUILD IT</Typography>
          <Typography variant="body1" textAlign={"justify"} pt={3}>
            We specialize in construction of retaining walls, fencing, decking,
            and paving.
            <br /> Our object has always been to offer exceptional customer
            service experience to all our clients. <br />
            We understand that your property is one of your most important
            investments, and that’s why we are here to add the beauty and the
            value to your property.
            <br /> Trust the experts at PNJ Landscapers to provide you with the
            best solution that are tailored to your specific requirements. So,
            if you are looking to revamp your outdoor area or improve security
            around your home, we can create stylish decks, retaining walls,
            timber fencing, and paving that functions well and looks great.
            <br /> Book in your free site visit and no obligation quote, and we
            will provide a range of options to suit you.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              mt: 6,
              fontFamily: "Poppins",
              fontSize: 28,
              textTransform: "capitalize",
              color: "#000",
              px: 10,
              py: 2,
              borderRadius: "50px",
              border: "1px solid #000",
            }}
            onClick={() => {
              window.open("/getaquote", "_self");
            }}
          >
            Get a Quote
          </Button>
        </Grid>
        <Grid
          item
          sm={6}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
        >
          <Box
            sx={{
              width: "95%",
              margin: "auto",
              backgroundColor: "#000",
              borderRadius: 3,
              borderBlockColor: "rgba(0, 0, 0, 0.52)",
              borderWidth: 1,
              alignItems: "center",
              pt: 3,
              minHeight: "95%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${landscapingImg2})`,
            }}
          ></Box>
        </Grid>
      </Grid>
      <Grid sx={{ backgroundColor: "white" }} container spacing={10} p={5}>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Construction of Retaining Walls
            </Typography>
            <Typography
              variant="body1"
              p={3}
              color={"black"}
              textAlign={"justify"}
              pt={3}
            >
              Our team excels in crafting functional and aesthetic retaining
              walls. Whether it's managing soil erosion, creating levels, or
              defining areas, our durable walls elevate your outdoor space.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Fencing
            </Typography>
            <Typography
              variant="body1"
              p={3}
              color={"black"}
              textAlign={"justify"}
              pt={3}
            >
              Elevate privacy, security, and visual appeal with our custom
              fencing. From classic wood to modern metal, our options cater to
              your preferences while harmonizing with your landscape.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Decking
            </Typography>

            <Typography
              variant="body1"
              p={3}
              color={"black"}
              textAlign={"justify"}
              pt={3}
            >
              Enhance outdoor living with our seamless, premium decks. Designed
              for relaxation, entertainment, and gatherings, they merge with
              your landscape. Crafted for durability, safety, and style.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Paving
            </Typography>
            <Typography
              variant="body1"
              p={3}
              color={"black"}
              textAlign={"justify"}
              pt={3}
            >
              Our paving enhances charm and function. Skilled pavers create
              intricate designs, complementing your landscape. Whether
              traditional or contemporary, our solutions transform outdoors into
              inviting spaces.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default Landscaping;
