import { Box } from "@mui/system";
import quoteBanner from "../../../src/images/quote.png";
import {
  Button,
  Grid,
  Typography,
  Hidden,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function GetAQuote() {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );

  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
    checkbox9: false,
    checkbox10: false,
    checkbox11: false,
    checkbox12: false,
    checkbox13: false,
    checkbox14: false,
    checkbox15: false,
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    suburb: "",
    postalCode: "",
    findOutAboutUs: "",
    message: "",
    services: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    suburb: "",
    postalCode: "",
    findOutAboutUs: "",
    message: "",
    services: "",
  });

  const convertToTitleCase = (input: string): string => {
    const words = input.split(/(?=[A-Z])/);
    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return titleCaseWords.join(" ");
  };

  const validateFields = () => {
    // Check required fields and set error messages if empty
    let formErrors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: "",
      suburb: "",
      postalCode: "",
      findOutAboutUs: "",
      message: "",
      services: "",
    };

    let isValid = true;
    if (!formData.firstName) {
      formErrors.firstName = "First Name is required";
      isValid = false;
    }
    if (!formData.lastName) {
      formErrors.lastName = "Last Name is required";
      isValid = false;
    }
    if (!formData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      formErrors.email = "Invalid email address";
      isValid = false;
    }

    if (!formData.phoneNumber) {
      formErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    }
    if (!formData.address) {
      formErrors.address = "Address is required";
      isValid = false;
    }
    if (!formData.suburb) {
      formErrors.suburb = "Suburb is required";
      isValid = false;
    }
    if (!formData.postalCode) {
      formErrors.postalCode = "Postal Code is required";
      isValid = false;
    }
    if (!formData.findOutAboutUs) {
      formErrors.findOutAboutUs = "Find Out About Us is required";
      isValid = false;
    }
    if (!formData.message) {
      formErrors.message = "Message is required";
      isValid = false;
    }
    if (!formData.services) {
      formErrors.services = "Select at least one service";
      isValid = false;
    }
    // Add similar checks for other fields

    setErrors(formErrors);

    // Return true if no errors exist, indicating validation success
    return isValid;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const selectedOptions = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    formData.services = selectedOptions.join(", ");

    const isValid = validateFields();

    if (isValid) {
      try {
        const response = await axios.post(
          "https://us-central1-pnjlandscapers.cloudfunctions.net/sendQuotation",
          formData
        );

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          address: "",
          suburb: "",
          postalCode: "",
          findOutAboutUs: "",
          message: "",
          services: "",
        });

        setCheckboxes({
          checkbox1: false,
          checkbox2: false,
          checkbox3: false,
          checkbox4: false,
          checkbox5: false,
          checkbox6: false,
          checkbox7: false,
          checkbox8: false,
          checkbox9: false,
          checkbox10: false,
          checkbox11: false,
          checkbox12: false,
          checkbox13: false,
          checkbox14: false,
          checkbox15: false,
        });

        toast.success(
          "Your Quotation Request has been Sent Successfully. Our team will contact you soon.",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } catch (error) {
        console.error(error);
      }
    } 
  };

  const handleCheckboxChange = (event: {
    target: { name: any; checked: any; id: any };
  }) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });

    setCheckboxes({
      ...checkboxes,
      ["checkbox"+event.target.id]: event.target.checked,
    });

    console.log(checkedItems);
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : convertToTitleCase(name) + " is required",
    }));

    if (name === "email") {
      if (!emailPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid email address",
        }));
      }
    }
  };

  const handleInputBlur = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : convertToTitleCase(name) + " is required",
    }));

    if (name === "email") {
      if (!emailPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid email address",
        }));
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "green",
          backgroundImage: `url(${quoteBanner})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          px: 5,
          pt: 10,
        }}
      >
        <Typography
          variant="h1"
          color={"#FFF"}
          py={20}
          px={3}
          textAlign={"center"}
        >
          Get a Quote
        </Typography>
      </Box>
      <Box
        sx={{ backgroundColor: "white", px: 5 }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="body1" p={5} textAlign={"center"}>
          Ready to turn your outdoor dreams into reality? Our team is excited to
          bring your vision to life. <br />
          Share your ideas, preferences, and any specific requirements you have.
          <br />
          Whether it's a vibrant garden, a stunning deck, or a functional
          retaining wall, we're here to craft tailored solutions that match your
          needs and budget. <br />
          Fill out the form, and let's start the journey to creating your
          perfect outdoor space.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid sx={{ backgroundColor: "white" }} container spacing={4} p={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              required
              error={!!errors.firstName}
              helperText={errors.firstName}
              value={formData.firstName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              required
              error={!!errors.lastName}
              helperText={errors.lastName}
              value={formData.lastName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Emali"
              variant="outlined"
              fullWidth
              name="email"
              required
              error={!!errors.email}
              helperText={errors.email}
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              name="phoneNumber"
              required
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              value={formData.phoneNumber}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              name="address"
              required
              error={!!errors.address}
              helperText={errors.address}
              value={formData.address}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Suburb"
              variant="outlined"
              fullWidth
              name="suburb"
              required
              error={!!errors.suburb}
              helperText={errors.suburb}
              value={formData.suburb}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Postal Code"
              variant="outlined"
              fullWidth
              name="postalCode"
              required
              error={!!errors.postalCode}
              helperText={errors.postalCode}
              value={formData.postalCode}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} display={"flex"} flexDirection={"column"}>
            <FormControl error={!!errors.services}>
              <FormLabel
                component="legend"
                style={{ color: "black", backgroundColor: "white" }}
              >
                What services do you need? *
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox1"]}
                      name={"Lawn Mowing"}
                      id={"1"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Lawn Mowing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox2"]}
                      name={"Ready Lawns"}
                      id={"2"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Ready Lawns"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox3"]}
                      name={"Hedge trimming"}
                      id={"3"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Hedge trimming"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox4"]}
                      name={"Pruning"}
                      id={"4"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Pruning"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox5"]}
                      id={"5"}
                      name={"Garden bed, Lawn bed preparing"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Garden bed, Lawn bed preparing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox6"]}
                      name={"Fertilising"}
                      id={"6"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Fertilising"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox7"]}
                      name={"Mulching"}
                      id={"7"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Mulching"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox8"]}
                      name={"Irrigation"}
                      id={"8"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Irrigation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox9"]}
                      name={"Section Tidy"}
                      id={"9"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Section Tidy"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox10"]}
                      name={"Water blasting"}
                      id={"10"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Water blasting"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox11"]}
                      name={"New planting"}
                      id={"11"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="New planting"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox12"]}
                      name={"Construction of retaining walls"}
                      id={"12"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Construction of retaining walls"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox13"]}
                      name={"Fencing"}
                      id={"13"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Fencing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox14"]}
                      name={"Decking"}
                      id={"14"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Decking"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={checkboxes["checkbox15"]}
                      name={"Paving"}
                      id={"15"}
                      style={{
                        borderColor: "black",
                        borderWidth: "1px",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    />
                  }
                  label="Paving"
                />
                {errors.services && (
                  <Typography color="error" style={{ fontSize: 14 }}>
                    {errors.services}
                  </Typography>
                )}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl
              fullWidth
              variant="outlined"
              error={!!errors.findOutAboutUs}
            >
              <InputLabel style={{ color: "black", backgroundColor: "white" }}>
                Where did you find out about us?
              </InputLabel>
              <Select
                name="findOutAboutUs"
                value={formData.findOutAboutUs}
                onChange={handleInputChange}
                label="findOutAboutUs"
                style={{
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                }}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Google">Google</MenuItem>
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="Referred by a Friend">
                  Referred by a Friend
                </MenuItem>
                <MenuItem value="Saw a Vehicle">Saw a Vehicle</MenuItem>
                <MenuItem value="Existing or Old Client">
                  Existing or Old Client
                </MenuItem>
                <MenuItem value="Print, TV, Radio">Print, TV, Radio</MenuItem>
                <MenuItem value="Ministry of Social Development (MSD)">
                  Ministry of Social Development (MSD)
                </MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
              {errors.findOutAboutUs && (
                <Typography color="error" style={{ fontSize: 14 }}>
                  {errors.findOutAboutUs}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              name="message"
              required
              error={!!errors.message}
              helperText={errors.message}
              value={formData.message}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
              multiline={true}
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={12} display={"flex"} alignContent={"center"}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                mt: 6,
                fontFamily: "Poppins",
                fontSize: 28,
                textTransform: "capitalize",
                color: "#000",
                px: 10,
                py: 2,
                borderRadius: "50px",
                border: "1px solid #000",
                margin: "auto",
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default GetAQuote;
