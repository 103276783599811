import { MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import React from "react";
import logo from "../../../src/images/logo-new.png";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      flex={1}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.52);",
        px: 2,
        paddingY: 2,
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: "10",
      }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={6}
          display={"flex"}
          flexDirection={"row"}
          alignContent={"center"}
        >
          <Hidden mdUp>
            <Box sx={{ zIndex: 99999, marginTop: 1 }}>
              <IconButton
                aria-label="more"
                id="long-button"
                style={{ backgroundColor: "white" }}
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: "100%",
                  },
                }}
              >
                <MenuItem
                  key={"landscaping"}
                  onClick={() => {
                    window.open("/", "_self");
                    handleClose();
                  }}
                >
                  Home
                </MenuItem>
                <MenuItem
                  key={"landscaping"}
                  onClick={() => {
                    window.open("landscaping", "_self");
                    handleClose();
                  }}
                >
                  Landscaping
                </MenuItem>
                <MenuItem
                  key={"gardening"}
                  onClick={() => {
                    window.open("gardening", "_self");
                    handleClose();
                  }}
                >
                  Gardening
                </MenuItem>
                <MenuItem
                  key={"aboutus"}
                  onClick={() => {
                    window.open("aboutus", "_self");
                    handleClose();
                  }}
                >
                  About Us
                </MenuItem>
                <MenuItem
                  key={"getaquote"}
                  onClick={() => {
                    window.open("getaquote", "_self");
                    handleClose();
                  }}
                >
                  Get a Quote
                </MenuItem>
                <MenuItem
                  key={"contactus"}
                  onClick={() => {
                    window.open("contactus", "_self");
                    handleClose();
                  }}
                >
                  Contact Us
                </MenuItem>
              </Menu>
            </Box>
          </Hidden>
          <Box>
            <Box display={"flex"} flexDirection={"row"}>
              <Button
                onClick={() => {
                  window.open("/", "_self");
                }}
              >
                <img
                  src={logo}
                  alt="Logo"
                  style={{ maxWidth: "250px", height: "auto" }}
                />
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Hidden mdDown>
            <Box display={"flex"} flexDirection={"row"} alignContent={"end"}>
              <Typography variant="body2" sx={{ p: 2 }}>
                <Link href="/">Home</Link>
              </Typography>
              <Typography variant="body2" sx={{ p: 2 }}>
                <Link href="landscaping">Landscaping</Link>
              </Typography>
              <Typography variant="body2" sx={{ p: 2 }}>
                <Link href="gardening">Gardening</Link>
              </Typography>
              <Typography variant="body2" sx={{ p: 2 }}>
                <Link href="aboutus">About Us</Link>
              </Typography>
              <Typography variant="body2" sx={{ p: 2 }}>
                <Link href="getaquote">Get a Quote</Link>
              </Typography>
              <Typography variant="body2" sx={{ p: 2 }}>
                <Link href="contactus">Contact Us</Link>
              </Typography>
            </Box>
          </Hidden>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
