import { Box } from "@mui/system";
import gardeningBanner from "../../../src/images/gardening-banner.png";
import gardeningImg2 from "../../../src/images/gardening-image-2.jpg";

import { Button, Grid, Typography, Hidden, IconButton } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { createBreakpoints } from '@mui/system';

function Gardening() {

  const breakpoints = createBreakpoints({});

  return (
    <>
      <Box
        sx={{
          backgroundColor: "green",
          backgroundImage: `url(${gardeningBanner})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          px: 5,
          paddingY: 10,
        }}
      >
        <Typography variant="h1" color={"#FFF"} mt={20} textAlign={"center"}>
          Gardening
        </Typography>
        <Typography variant="h3" color={"#FFF"} mt={1} textAlign={"center"}>
          Quilty that is granted
        </Typography>
        <Typography color={"#FFF"} mt={2} textAlign={"center"}>
          Don’t have the time or expertise to do it yourself? <br />
          We’ll take care of your property so you can relax and enjoy it.
        </Typography>
      </Box>
      <Grid sx={{ backgroundColor: "white" }} container spacing={4} p={5}>
        <Grid item sm={6}>
          <Typography variant="h2">
            DO NOT BOTHER ANYMORE – WE CAN TAKE CARE OF YOUR GARDEN
          </Typography>
          <Typography variant="body1" textAlign={"justify"} pt={3}>
            Do you want your garden to be well maintained without a hassle?
            <br />
            <br />
            Call PNJ gardening team and get a free quotation with a maintenance
            schedule to keep your garden maintained and looking great. our
            biggest pleasure is maintaining gardens so that they develop and
            improve with age. 
            <br />
            <br />
            We keep your garden beautiful with,
            <br />
            <ul>
              <li>Lawn Mowing</li>
              <li>Ready Lawns</li>
              <li>Hedge trimming</li>
              <li>Pruning</li>
              <li>Garden bed, Lawn bed preparing</li>
              <li>Fertilising</li>
              <li>Mulching</li>
              <li>Irrigation</li>
              <li>Section Tidy</li>
              <li>Water blasting</li>
              <li>New planting</li>
            </ul>
          </Typography>

          <Typography variant="body1" textAlign={"justify"} pt={2}>
            Regular garden maintenance ensures your garden is tidy, healthy and
            thriving.
            <br />
            So we are here to help. 
            <br />
            Call us or drop us a message and we will get back to you soon.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              mt: 6,
              fontFamily: "Poppins",
              fontSize: 28,
              textTransform: "capitalize",
              color: "#000",
              px: 10,
              py: 2,
              borderRadius: "50px",
              border: "1px solid #000",
            }}
            onClick={() => {
              window.open("/getaquote", "_self");
            }}
          >
            Get a Quote
          </Button>
        </Grid>
        <Grid
          item
          sm={6}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
        >
          <Box
            sx={{
              width: "95%",
              margin: "auto",
              borderRadius: 3,
              borderBlockColor: "rgba(0, 0, 0, 0.52)",
              borderWidth: 1,
              alignItems: "center",
              pt: 3,
              height: "95%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${gardeningImg2})`,
            }}
          ></Box>
        </Grid>
      </Grid>
      <Grid sx={{ backgroundColor: "white" }} container spacing={10} p={5}>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Lawn Mowing
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Our skilled team of professionals will meticulously trim your lawn
              to the perfect height, ensuring an even and well-manicured look
              that enhances the overall aesthetic of your outdoor space.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Ready Lawns
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Instantly revitalize your landscape with our Ready Lawns service.
              We provide you with lush, pre-grown turf that effortlessly
              transforms bare areas into vibrant green carpets, saving you time
              and allowing you to enjoy your new lawn sooner.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Hedge Trimming
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Our hedge trimming service is designed to create precise shapes
              and lines, giving your hedges a polished and organized appearance.
              We carefully trim to maintain healthy growth and to enhance the
              visual appeal of your property.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Pruning
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Proper pruning not only enhances the beauty of your plants but
              also promotes healthier growth. Our pruning experts will
              skillfully remove dead or overgrown branches, shaping your plants
              for optimal growth and vitality.
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Garden Bed, Lawn Bed Preparing
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              We lay the groundwork for a flourishing landscape by meticulously
              preparing garden and lawn beds. Our team ensures proper soil
              conditioning, weed removal, and grading, providing the ideal
              foundation for your plants to thrive.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Fertilising
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Our specialized fertilization treatments provide essential
              nutrients that your plants crave for robust growth. We tailor our
              fertilizing approach to the specific needs of your landscape,
              ensuring lasting health and vibrancy.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Mulching
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Mulching offers multiple benefits, from retaining soil moisture to
              suppressing weed growth. Our carefully selected mulching materials
              not only serve functional purposes but also add an attractive
              finish to your garden beds.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Irrigation
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Keeping your landscape adequately watered is crucial for its
              longevity. Our irrigation systems are tailored to your landscape's
              unique layout, ensuring that every corner receives the right
              amount of water to flourish.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Section Tidy
            </Typography>

            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Our Section Tidy service is a comprehensive approach to
              maintaining the overall tidiness of your outdoor space. From
              removing debris to ensuring pathways are clear, we create an
              inviting environment that impresses guests and residents alike.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Water Blasting
            </Typography>

            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Renew the look of hard surfaces like decks, patios, and driveways
              with our Water Blasting service. By using high-pressure water, we
              effectively remove built-up dirt, grime, and stains, restoring
              surfaces to their former glory.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down('md')]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              New Planting
            </Typography>
            <Typography
              variant="body1"
              p={3}
              textAlign={"justify"}
              pt={3}
            >
              Introduce new life to your landscape with our New Planting
              service. Our experts help you select the right plants for your
              environment and arrange them in a way that complements your
              existing layout while adding fresh colors and textures.{" "}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default Gardening;
