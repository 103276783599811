import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home.view";
import LawnMoving from "./pages/landscaping/landscaping.view";
import Gardening from "./pages/gardening/gardening.view";
import GetAQuote from "./pages/getaquote/getaquote.view";
import Aboutus from "./pages/aboutus/aboutus.view";
import ContactUs from "./pages/contactus/contactus.view";
import Feedback from "./pages/feedback/feedback.view";
import Header from "./components/header/header.view";
import Footer from "./components/footer/footer.view";

import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import pnjTheme from "./theme";
import { ThemeProvider } from "@mui/system";
import Landscaping from "./pages/landscaping/landscaping.view";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={pnjTheme}>
      <TawkMessengerReact
                propertyId="64ecf179b2d3e13950ec994e"
                widgetId="1h8uouhlu"/>
        <Header />
        <Fragment>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/landscaping" element={<Landscaping />} />
            <Route path="/gardening" element={<Gardening />} />
            <Route path="/getaquote" element={<GetAQuote />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/feedback" element={<Feedback />} />
          </Routes>
        </Fragment>
        <Footer />
      </ThemeProvider>
    </Router>
  );
};
export default App;
