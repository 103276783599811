import { Box } from "@mui/system";
import homeBanner from "../../../src/images/home-banner.png";
import { Button, Grid, Typography, Hidden, IconButton } from "@mui/material";
import {
  ArrowForwardIos,
  EmailRounded,
  FacebookRounded,
  FacebookTwoTone,
  Person2Rounded,
  PhoneRounded,
  YouTube,
  YoutubeSearchedForTwoTone,
} from "@mui/icons-material";
import forestImg from "../../../src/images/forest.png";
import landscapingImg1 from "../../../src/images/landscaping-image-1.jpg";
import gardeningImg1 from "../../../src/images/gardening-image-1.jpg";
import { createBreakpoints } from "@mui/system";

function Home() {
  const breakpoints = createBreakpoints({});

  return (
    <>
      <Box
        sx={{
          backgroundColor: "green",
          backgroundImage: `url(${homeBanner})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          px: 5,
          paddingY: 10,
        }}
      >
        <Typography
          variant="h1"
          color={"#FFF"}
          mt={20}
          pr={1}
          pb={2}
          textTransform={"capitalize"}
        >
          Your lawn and landscape <br />
          will receive the care <br />
          they truly deserve
        </Typography>
        <Typography variant="h3" color={"#FFF"} mt={1}>
          Don’t have the time or expertise to do it yourself? <br />
          We’ll take care of your property so you can relax and enjoy it.
        </Typography>
        <Button
          variant="outlined"
          sx={{
            mt: 6,
            fontFamily: "Poppins",
            fontSize: 28,
            textTransform: "capitalize",
            px: 14,
            py: 2,
            borderRadius: "50px",
            border: "1px solid #FFF",
            background: "rgba(255, 255, 255, 0.42)",
          }}
          onClick={() => {
            window.open("/getaquote", "_self");
          }}
        >
          Get a Quote
        </Button>
      </Box>
      <Hidden mdDown>
        <Grid
          container
          spacing={5}
          sx={{ position: "absolute", mt: -15 }}
          p={5}
        >
          <Grid item sm={6}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#000",
                margin: "auto",
                borderRadius: 3,
                alignItems: "center",
                pt: 3,
              }}
            >
              <Box
                sx={{
                  borderColor: "white",
                  borderRadius: 28,
                  borderStyle: "solid",
                  borderWidth: 1,
                  width: 52,
                  height: 52,
                  margin: "auto",
                }}
              >
                <Typography
                  variant="body1"
                  color={"white"}
                  margin={"auto"}
                  pl={1}
                  pt={1}
                >
                  <PhoneRounded fontSize={"large"} />
                </Typography>
              </Box>
              <Typography variant="h3" color={"white"} textAlign={"center"}>
                CALL +64 22 6875760
              </Typography>
              <Typography
                variant="body1"
                color={"white"}
                px={3}
                pb={3}
                textAlign={"center"}
                fontSize={14}
              >
                Speak to a real person anytime, 24/7!
                <br />
                <br />
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#000",
                margin: "auto",
                borderRadius: 3,
                alignItems: "center",
                pt: 3,
              }}
            >
              <Box
                sx={{
                  borderColor: "white",
                  borderRadius: 28,
                  borderStyle: "solid",
                  borderWidth: 1,
                  width: 52,
                  height: 52,
                  margin: "auto",
                }}
              >
                <Typography
                  variant="body1"
                  color={"white"}
                  margin={"auto"}
                  pl={1}
                  pt={1}
                >
                  <EmailRounded fontSize={"large"} />
                </Typography>
              </Box>
              <Typography variant="h3" color={"white"} textAlign={"center"}>
                GET A FREE QUOTE
              </Typography>
              <Typography
                variant="body1"
                color={"white"}
                px={3}
                pb={3}
                textAlign={"center"}
                fontSize={14}
              >
                Online requests are normally responded to within 2 business
                hours!
                <br />
                <br />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Box
        sx={{ backgroundColor: "white", px: 5 }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="h1" pt={35} textAlign={"center"}>
          Services We Offer
        </Typography>
        <Typography variant="body1" pt={3} textAlign={"center"}>
          Welcome to our comprehensive range of professional landscaping
          services. <br />
          At pnjlandscapers, we take pride in transforming outdoor spaces into
          stunning havens through our diverse offerings. <br />
          Our services include precision Lawn Mowing to maintain the lushness of
          your green carpets, while our Ready Lawns provide an instant vibrancy
          to your surroundings. Our expert touch extends to Hedge Trimming and
          Pruning, ensuring your foliage is sculpted to perfection. <br />{" "}
          <br />
          For creating the ideal foundation, we specialize in Garden Bed and
          Lawn Bed preparation, followed by strategic Fertilizing and Mulching
          for sustained vitality. Our precise Irrigation solutions keep your
          landscape refreshed, while our Section Tidy service maintains the
          overall aesthetics. From the rejuvenating effects of Water Blasting to
          the introduction of New Plantings, our dedicated team is committed to
          curating a landscape that reflects your vision and flourishes with our
          care.
          <br />
        </Typography>
      </Box>
      <Grid sx={{ backgroundColor: "white" }} container spacing={10} p={5}>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Lawn Mowing
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Our skilled team of professionals will meticulously trim your lawn
              to the perfect height, ensuring an even and well-manicured look
              that enhances the overall aesthetic of your outdoor space.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Ready Lawns
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Instantly revitalize your landscape with our Ready Lawns service.
              We provide you with lush, pre-grown turf that effortlessly
              transforms bare areas into vibrant green carpets, saving you time
              and allowing you to enjoy your new lawn sooner.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Hedge Trimming
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Our hedge trimming service is designed to create precise shapes
              and lines, giving your hedges a polished and organized appearance.
              We carefully trim to maintain healthy growth and to enhance the
              visual appeal of your property.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Pruning
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Proper pruning not only enhances the beauty of your plants but
              also promotes healthier growth. Our pruning experts will
              skillfully remove dead or overgrown branches, shaping your plants
              for optimal growth and vitality.
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Garden Bed, Lawn Bed Preparing
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              We lay the groundwork for a flourishing landscape by meticulously
              preparing garden and lawn beds. Our team ensures proper soil
              conditioning, weed removal, and grading, providing the ideal
              foundation for your plants to thrive.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Fertilising
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Our specialized fertilization treatments provide essential
              nutrients that your plants crave for robust growth. We tailor our
              fertilizing approach to the specific needs of your landscape,
              ensuring lasting health and vibrancy.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Mulching
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Mulching offers multiple benefits, from retaining soil moisture to
              suppressing weed growth. Our carefully selected mulching materials
              not only serve functional purposes but also add an attractive
              finish to your garden beds.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Irrigation
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Keeping your landscape adequately watered is crucial for its
              longevity. Our irrigation systems are tailored to your landscape's
              unique layout, ensuring that every corner receives the right
              amount of water to flourish.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Section Tidy
            </Typography>

            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Our Section Tidy service is a comprehensive approach to
              maintaining the overall tidiness of your outdoor space. From
              removing debris to ensuring pathways are clear, we create an
              inviting environment that impresses guests and residents alike.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Water Blasting
            </Typography>

            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Renew the look of hard surfaces like decks, patios, and driveways
              with our Water Blasting service. By using high-pressure water, we
              effectively remove built-up dirt, grime, and stains, restoring
              surfaces to their former glory.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              New Planting
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Introduce new life to your landscape with our New Planting
              service. Our experts help you select the right plants for your
              environment and arrange them in a way that complements your
              existing layout while adding fresh colors and textures.{" "}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Construction of Retaining Walls
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Our team excels in crafting functional and aesthetic retaining
              walls. Whether it's managing soil erosion, creating levels, or
              defining areas, our durable walls elevate your outdoor space.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Fencing
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Elevate privacy, security, and visual appeal with our custom
              fencing. From classic wood to modern metal, our options cater to
              your preferences while harmonizing with your landscape.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Decking
            </Typography>

            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Enhance outdoor living with our seamless, premium decks. Designed
              for relaxation, entertainment, and gatherings, they merge with
              your landscape. Crafted for durability, safety, and style.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={4}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              borderRadius: 3,
              alignItems: "center",
              pt: 3,
              [breakpoints.down("md")]: {
                height: 350,
              },
              height: 500,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography variant="h3" fontWeight={700} textAlign={"left"} px={3}>
              Paving
            </Typography>
            <Typography variant="body1" p={3} textAlign={"justify"} pt={3}>
              Our paving enhances charm and function. Skilled pavers create
              intricate designs, complementing your landscape. Whether
              traditional or contemporary, our solutions transform outdoors into
              inviting spaces.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{ backgroundColor: "white" }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="h1" pt={15} textAlign={"center"}>
          Why Choose Us?
        </Typography>
      </Box>
      <Grid sx={{ backgroundColor: "white" }} container spacing={4} p={5}>
        <Grid item sm={12}>
          <Typography variant="body1" textAlign={"justify"} pt={3}>
            PNJ Landscapers stand out for good reasons. We're deeply rooted in
            Auckland, and Pradeep Nishantha's amazing landscaping skills make us
            special. With a long history of working in this field, we're experts
            in making outdoor spaces look incredible and peaceful.
            <br />
            <br />
            What makes us different is how seriously we take our work. We're
            really good at what we do, and we make sure to put a lot of care
            into each project. When you pick us, you're choosing a team that
            knows what they're doing and loves making nature look its best.
            <br />
            <br />
            Whether you're in Auckland or anywhere else, we're here to help you
            create outdoor spaces that feel just right – where nature and the
            things we build come together in a wonderful way. So, if you want to
            work with experts who care, choose PNJ Landscapers. We're all about
            skills, creativity, and doing things with a lot of heart.
          </Typography>
        </Grid>
        {/*<Grid
          item
          sm={6}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
        >
          <Box
            sx={{
              width: "45%",
              margin: "auto",
              borderRadius: 3,
              borderBlockColor: "rgba(0, 0, 0, 0.52)",
              borderWidth: 1,
              alignItems: "center",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              pt: 3,
              minHeight: "45%",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Box
                sx={{
                  borderRadius: 28,
                  width: 52,
                  height: 52,
                  margin: "auto",
                  backgroundColor: "#BCCAE3",
                }}
              >
                <Typography
                  variant="body1"
                  color={"#1D48E1"}
                  margin={"auto"}
                  pl={1}
                  pt={1}
                >
                  <PhoneRounded fontSize={"large"} />
                </Typography>
              </Box>
              <Typography textAlign={"center"} fontWeight={500}>
                Fully Insured
              </Typography>
              <Typography textAlign={"center"} fontWeight={500} fontSize={14}>
                Our landscapers are fully insured for their safety & your piece
                of mind
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "45%",
              margin: "auto",
              borderRadius: 3,
              borderBlockColor: "rgba(0, 0, 0, 0.52)",
              borderWidth: 1,
              alignItems: "center",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              pt: 3,
              minHeight: "45%",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Box
                sx={{
                  borderRadius: 28,
                  width: 52,
                  height: 52,
                  margin: "auto",
                  backgroundColor: "#BCCAE3",
                }}
              >
                <Typography
                  variant="body1"
                  color={"#1D48E1"}
                  margin={"auto"}
                  pl={1}
                  pt={1}
                >
                  <PhoneRounded fontSize={"large"} />
                </Typography>
              </Box>
              <Typography textAlign={"center"} fontWeight={500}>
                Fully Insured
              </Typography>
              <Typography textAlign={"center"} fontWeight={500} fontSize={14}>
                Our landscapers are fully insured for their safety & your piece
                of mind
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "45%",
              margin: "auto",
              borderRadius: 3,
              borderBlockColor: "rgba(0, 0, 0, 0.52)",
              borderWidth: 1,
              alignItems: "center",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              pt: 3,
              minHeight: "45%",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Box
                sx={{
                  borderRadius: 28,
                  width: 52,
                  height: 52,
                  margin: "auto",
                  backgroundColor: "#BCCAE3",
                }}
              >
                <Typography
                  variant="body1"
                  color={"#1D48E1"}
                  margin={"auto"}
                  pl={1}
                  pt={1}
                >
                  <PhoneRounded fontSize={"large"} />
                </Typography>
              </Box>
              <Typography textAlign={"center"} fontWeight={500}>
                Fully Insured
              </Typography>
              <Typography textAlign={"center"} fontWeight={500} fontSize={14}>
                Our landscapers are fully insured for their safety & your piece
                of mind
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "45%",
              margin: "auto",
              borderRadius: 3,
              borderBlockColor: "rgba(0, 0, 0, 0.52)",
              borderWidth: 1,
              alignItems: "center",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              pt: 3,
              minHeight: "45%",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Box
                sx={{
                  borderRadius: 28,
                  width: 52,
                  height: 52,
                  margin: "auto",
                  backgroundColor: "#BCCAE3",
                }}
              >
                <Typography
                  variant="body1"
                  color={"#1D48E1"}
                  margin={"auto"}
                  pl={1}
                  pt={1}
                >
                  <PhoneRounded fontSize={"large"} />
                </Typography>
              </Box>
              <Typography textAlign={"center"} fontWeight={500}>
                Fully Insured
              </Typography>
              <Typography textAlign={"center"} fontWeight={500} fontSize={14}>
                Our landscapers are fully insured for their safety & your piece
                of mind
              </Typography>
            </Box>
          </Box>
        </Grid> */}
      </Grid>
      <Box
        sx={{ backgroundColor: "black", px: 5 }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        py={10}
      >
        <Typography variant="h1" textAlign={"center"} color={"white"}>
          Let us nurture your landscape while you reclaim your valuable free
          time.
        </Typography>
        <Hidden mdUp>
          <Box
            sx={{
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                mt: 6,
                mr: 2,
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 24,
                textTransform: "capitalize",
                px: 5,
                py: 5,
                borderRadius: "15px",
                background: "rgba(245, 245, 245, 0.21)",
              }}
              onClick={() => {
                window.open("/contactus", "_self");
              }}
            >
              GET IN TOUCH
            </Button>
            <Button
              variant="outlined"
              sx={{
                mt: 6,
                mr: 2,
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 24,
                textTransform: "capitalize",
                px: 5,
                py: 5,
                borderRadius: "15px",
                background: "rgba(245, 245, 245, 0.21)",
              }}
              onClick={() => {
                window.open("/getaquote", "_self");
              }}
            >
              OUR QUOTE
            </Button>
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box
            sx={{
              alignContent: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                mt: 6,
                mr: 2,
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 24,
                textTransform: "capitalize",
                px: 5,
                py: 5,
                borderRadius: "15px",
                background: "rgba(245, 245, 245, 0.21)",
              }}
              onClick={() => {
                window.open("/contactus", "_self");
              }}
            >
              GET IN TOUCH
            </Button>
            <Button
              variant="outlined"
              sx={{
                mt: 6,
                mr: 2,
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: 24,
                textTransform: "capitalize",
                px: 5,
                py: 5,
                borderRadius: "15px",
                background: "rgba(245, 245, 245, 0.21)",
              }}
              onClick={() => {
                window.open("/getaquote", "_self");
              }}
            >
              OUR QUOTE
            </Button>
          </Box>
        </Hidden>
      </Box>
      <Grid sx={{ backgroundColor: "white" }} container spacing={4} p={5}>
        <Grid item sm={6} alignItems={"start"}>
          <Typography variant="h4" color={"black"}>
            Landscaping Services
          </Typography>
          <Typography variant="h3" color={"black"}>
            Take the stress out of keeping your lows move and tidy
          </Typography>
          <Typography variant="body1" textAlign={"justify"} pt={3}>
            We specialize in retaining walls, fencing, decking, and paving. Our
            goal is exceptional customer service. Your property matters, and
            we're here to enhance its beauty and value. Count on PNJ Landscapers
            for tailored solutions. Whether you want to enhance your outdoor
            space or improve security, we create functional, stylish decks,
            walls, fencing, and paving. Schedule a free site visit for
            personalized options.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              mt: 6,
              fontFamily: "Poppins",
              fontSize: 28,
              textTransform: "capitalize",
              color: "#000",
              px: 10,
              py: 2,
              borderRadius: "50px",
              border: "1px solid #000",
            }}
            onClick={() => {
              window.open("/landscaping", "_self");
            }}
          >
            Read More
          </Button>
        </Grid>
        <Grid
          item
          sm={6}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
        >
          <Box
            sx={{
              width: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${landscapingImg1})`,
              px: 5,
              paddingY: 10,
            }}
          ></Box>
        </Grid>
        <Grid
          item
          sm={6}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
        >
          <Box
            sx={{
              width: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${gardeningImg1})`,
              px: 5,
              paddingY: 10,
            }}
          ></Box>
        </Grid>
        <Grid item sm={6} alignItems={"start"}>
          <Typography variant="h4" color={"black"}>
            Gardening Services
          </Typography>
          <Typography variant="h3" color={"black"}>
            Take the stress out of keeping your lows move and tidy
          </Typography>
          <Typography variant="body1" textAlign={"justify"} pt={3}>
            Wouldn't it be wonderful to have a well-maintained garden without
            the hassle? Reach out to PNJ's gardening team and receive a free
            quote along with a maintenance schedule tailored to keep your garden
            looking its best. Our utmost joy lies in tending to gardens,
            nurturing them to flourish over time. From lawn mowing and hedge
            trimming to irrigation and new plantings, we offer a comprehensive
            range of services to ensure your garden's beauty. Regular
            maintenance guarantees a tidy, thriving garden. Let us assist you –
            give us a call or drop a message, and we'll be in touch shortly.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              mt: 6,
              fontFamily: "Poppins",
              fontSize: 28,
              textTransform: "capitalize",
              color: "#000",
              px: 10,
              py: 2,
              borderRadius: "50px",
              border: "1px solid #000",
            }}
            onClick={() => {
              window.open("/gardening", "_self");
            }}
          >
            Read More
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{ backgroundColor: "#D9D9D9" }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        py={10}
      ></Box>
      <Box
        sx={{ backgroundColor: "white" }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        py={10}
      >
        <Typography>Follow Us On</Typography>
        <Box>
          <IconButton
            aria-label="facebook"
            size="large"
            onClick={() => {
              window.open(
                "https://web.facebook.com/profile.php?id=61550746010277&sk=about",
                "_blank"
              );
            }}
          >
            <FacebookTwoTone fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="youtube"
            size="large"
            onClick={() => {
              window.open("https://www.youtube.com/@PJLandscapers", "_blank");
            }}
          >
            <YouTube fontSize="large" />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
export default Home;
