import { Box, Grid, Link, Typography } from "@mui/material";
import footerBG from "../../../src/images/footer-bg.png";
import { EmailTwoTone, PhoneTwoTone } from "@mui/icons-material";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "green",
        backgroundImage: `url(${footerBG})`,
        px: 5,
        paddingY: 10,
        zIndex: 99999
      }}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="start"
      >
        <Grid item xs={12} sm={6} lg={3} p={5}>
          <Typography variant="h4" pb={2}>
            PNJ Landscapers
          </Typography>
          <Typography variant="body2" align="justify">
            PNJ Lawn mowing Services was established in Auckland by Pradeep
            Nishantha in 2019, after working as a landscaper for multiple
            landscaping companies in New Zealand.{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} p={5}>
          <Typography variant="h4" pb={2}>
            Useful Links
          </Typography>
          <Typography variant="body2">
            <Link href="home">Home</Link>
          </Typography>
          <Typography variant="body2">
            <Link href="landscaping">Landscaping</Link>
          </Typography>
          <Typography variant="body2">
            <Link href="gardening">Gardening</Link>
          </Typography>
          <Typography variant="body2">
            <Link href="getaquote">Get A Quote</Link>
          </Typography>
          <Typography variant="body2">
            <Link href="aboutus">About Us</Link>
          </Typography>
          <Typography variant="body2">
            <Link href="#">Feedback</Link>
          </Typography>
          <Typography variant="body2">
            <Link href="contactus">Contact Us</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} p={5}>
          <Typography variant="h4" pb={2}>
            Services
          </Typography>
          <Typography variant="body2">
            <Link href="landscaping">Landscaping</Link>
          </Typography>
          <Typography variant="body2">
            <Link href="gardening">Gardening</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} p={5}>
          <Typography variant="h4" pb={2}>
            Contact
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" pr={1}>
              <EmailTwoTone />
            </Typography>
            <Typography variant="body2">
              <Link href="mailto:pnjlandscapers@gmail.com">
                pnjlandscapers@gmail.com
              </Link>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" pr={1}>
              <PhoneTwoTone />
            </Typography>
            <Typography variant="body2">
              <Link href="tel:+64226875760">+64 22 6875760</Link>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" pr={1}>
              <PhoneTwoTone />
            </Typography>
            <Typography variant="body2">
              <Link href="tel:+64224278494">+64 22 4278494</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
