import { Box } from "@mui/system";
import contactUs from "../../../src/images/contact-us.png";
import {
  Button,
  Grid,
  Typography,
  Hidden,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ArrowForwardIos, PhoneRounded } from "@mui/icons-material";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUs() {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const isValid = validateFields();

    if (isValid) {
      try {
        const response = await axios.post(
          "https://us-central1-pnjlandscapers.cloudfunctions.net/sendContactUs",
          formData
        );

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          address: "",
          message: "",
        });

        toast.success(
          "Your Request has been Sent Successfully. Our team will contact you soon.",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } catch (error) {
        console.error(error);
      }
    }
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    message: "",
  });

  const convertToTitleCase = (input: string): string => {
    const words = input.split(/(?=[A-Z])/);
    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return titleCaseWords.join(" ");
  };

  const validateFields = () => {
    // Check required fields and set error messages if empty

    let formErrors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: "",
      message: "",
    };

    let isValid = true;
    if (!formData.firstName) {
      formErrors.firstName = "First Name is required";
      isValid = false;
    }
    if (!formData.lastName) {
      formErrors.lastName = "Last Name is required";
      isValid = false;
    }
    if (!formData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    }else if(!emailPattern.test(formData.email)){
      formErrors.email = "Invalid email address";
      isValid = false;
    }

    if (!formData.phoneNumber) {
      formErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    }
    if (!formData.address) {
      formErrors.address = "Address is required";
      isValid = false;
    }
    if (!formData.message) {
      formErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(formErrors);

    // Return true if no errors exist, indicating validation success
    return isValid;
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : convertToTitleCase(name) + " is required",
    }));

    if(name === "email"){
      if(!emailPattern.test(value)){
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid email address",
        }));
      }
    }
  };

  const handleInputBlur = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : convertToTitleCase(name) + " is required",
    }));

    if(name === "email"){
      if(!emailPattern.test(value)){
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid email address",
        }));
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "green",
          backgroundImage: `url(${contactUs})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          px: 5,
          pt: 10,
        }}
      >
        <Typography
          variant="h1"
          color={"#FFF"}
          pt={10}
          px={3}
          textAlign={"center"}
        >
          Contact Us
        </Typography>
        <Typography variant="body1" textAlign={"center"} py={10} color={"white"}>
          We're here to listen and help. <br />
          Have a question, need a quote, or want to discuss your landscaping
          needs? <br />
          Whether you prefer a call or a message, we're just a contact away.
          <br />
          Your outdoor space transformation begins with a simple conversation.
          Reach out today and let's make your vision a reality.
        </Typography>
      </Box>
      <Box
        sx={{ backgroundColor: "white", px: 5, pt: 5 }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="h1" pt={10} textAlign={"center"}>
          Feedback
        </Typography>
        <Typography variant="body1" p={5} textAlign={"center"}>
          We also guarantee a call back within 24 hours! <br />
          Or you can call us directly on 022 68 75 760
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid sx={{ backgroundColor: "white" }} container spacing={4} p={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              error={!!errors.firstName}
              helperText={errors.firstName}
              value={formData.firstName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              error={!!errors.lastName}
              helperText={errors.lastName}
              value={formData.lastName}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Emali"
              variant="outlined"
              fullWidth
              name="email"
              error={!!errors.email}
              helperText={errors.email}
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile Number"
              variant="outlined"
              fullWidth
              name="phoneNumber"
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              value={formData.phoneNumber}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              name="address"
              error={!!errors.address}
              helperText={errors.address}
              value={formData.address}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              name="message"
              error={!!errors.message}
              helperText={errors.message}
              value={formData.message}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              margin="normal"
              InputLabelProps={{
                style: { color: "black", backgroundColor: "white" },
              }}
              InputProps={{
                style: {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
              multiline={true}
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={12} display={"flex"} alignContent={"center"}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                mt: 6,
                fontFamily: "Poppins",
                fontSize: 28,
                textTransform: "capitalize",
                color: "#000",
                px: 10,
                py: 2,
                borderRadius: "50px",
                border: "1px solid #000",
                margin: "auto",
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
export default ContactUs;
