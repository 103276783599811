import { Box } from "@mui/system";
import aboutUsBanner from "../../../src/images/about-us.png";
import aboutUsImg1 from "../../../src/images/about-us-1.jpg";
import { Button, Grid, Typography, Hidden, IconButton } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

function Aboutus() {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "green",
          backgroundImage: `url(${aboutUsBanner})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          px: 5,
          pt: 10,
        }}
      >
        <Typography variant="h1" color={"#FFF"} mt={10} textAlign={"center"}>
          About Us
        </Typography>
        <Typography variant="h3" color={"#FFF"} mt={1} textAlign={"center"}>
          Who we are
        </Typography>
        <Typography color={"#FFF"} pt={5} pb={15} textAlign={"center"}>
          PNJ Landscapers: Where expertise meets nature. <br />
          Crafting functional, beautiful outdoor spaces rooted in Auckland, led
          by Pradeep Nishantha's experience.
        </Typography>
      </Box>
      <Grid sx={{ backgroundColor: "white" }} container spacing={4} p={5}>
        <Grid item sm={6}>
          <Typography variant="body1" textAlign={"justify"} pt={3}>
            Welcome to PNJ Landscapers - PNJ Lawn mowing Services was
            established in Auckland by Pradeep Nishantha in 2019, after working
            as a landscaper for multiple landscaping companies in New Zealand.
            Before then he was a site manager in a landscaping company in home
            country since 2010. Started providing lawn mowing & gardening
            services and in 2022 it has grown up to a landscaping company.Since
            then, his hands on in the business and strive to ensure customers
            get the best services in the country.
          </Typography>
        </Grid>
        <Grid
          item
          sm={6}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
        >
          <Box
            sx={{
              width: "95%",
              margin: "auto",
              borderRadius: 3,
              borderBlockColor: "rgba(0, 0, 0, 0.52)",
              borderWidth: 1,
              alignItems: "center",
              pt: 3,
              height: "95%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url(${aboutUsImg1})`,
            }}
          ></Box>
        </Grid>
      </Grid>
      {/*<Box
        sx={{ backgroundColor: "white", px: 5 }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="h1" pt={10} textAlign={"center"}>
          Our History
        </Typography>
        <Typography variant="body1" pt={1} textAlign={"center"}>
          We provide exceptional Melbourne Landscaping services to a wide range
          of commercial
          <br />
          and residential properties and havemore than 10 years experience. Our
          experienced landscapers
          <br />
          set the standard each day in natural and synthetic turf, garden
          maintenance, irrigation,
          <br /> landscaping and hardscaping. We will whip your yard into shape
          in no time.
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: "auto",
          backgroundColor: "#000",
          borderBlockColor: "rgba(0, 0, 0, 0.52)",
          borderWidth: 1,
          alignItems: "center",
          pt: 3,
          height: "200px",
        }}
      ></Box>
      <Box
        sx={{ backgroundColor: "white", px: 5 }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography variant="h1" pt={10} textAlign={"center"}>
          Partnership and Membership
        </Typography>
        <Typography variant="body1" p={5} textAlign={"center"}>
          We provide exceptional Melbourne Landscaping services to a wide range
          of commercial <br />
          and residential properties and havemore than 10 years experience. Our
          experienced landscapers
        </Typography>
      </Box>*/}
    </>
  );
}
export default Aboutus;
